@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;0,800;1,400&display=swap');
 .c_h_e_t_i_r_n_a_d_c_a_t {
     display: flex;
     justify-content: flex-end;
     margin-top: 20px;
     margin-bottom: 20px;
     align-items: center;
     justify-content: center;
}
 .t_r_i_n_a_d_c_a_t {
     display: flex;
     width: 100%;
     justify-content: safe;
     flex-direction: column;
}
 p,li{
     line-height:1.6;
     padding-left:0px;
     color: #fef;
     position:relative;
}
 ul{
     position:relative;
     list-style: square outside;
}
 h1{

     font-size:30px;
     display: block;

     letter-spacing: 1px;
     margin-bottom: 0px;
     position: relative;
     color: #fef;
     padding-left:50px

}
/*  h2,h3,h4,h5,h6,p strong{ */
/* h1:before, h2:before, h3:before, h4:before, h5:before, h6:before,p strong:before { */
h1:before {
    width: 35px;
    height: 5px;
    content: '  ';
    background: #ff513a;
    left: 0px!important;
    top: 11px;
    position: absolute;
}
 body{
     margin:0px;
     font-family: 'Raleway', sans-serif;
     /*letter-spacing: 1px;*/
     position: relative;
     background:#131313;
     overflow-x:hidden;
}
 header .t_r_i div:hover:after {
     content: ' ';
     position: absolute;
     height: 3px;
     border-radius: 0px;
     left: 0px;
     width: 100%;
     display:block;
     top: 47px;
     background-color: #ff513a;
}
 header .t_r_i div:hover{
     color: #ff513a!important;
     z-index: 2;
}
 .c_h_e_t_i_r_e {
     margin-right: 0px!important;
}
 img{
     max-width:100%;
}
 a{
     color:inherit;
     text-decoration:none;
}
 header{
     height: 80px;
     background-color: #000001;
}
 .d_e_v_y_a_t{
     margin-bottom:30px;
     border-radius: 30px;
     margin-top: 10px;
}
 .d_v_a img {
     width: 80%;
     z-index: 3;
}
 .d_v_a{
     margin-left:0px!important;
}
 footer{
     height: 170px;
     margin-top:40px;
     background: #131313;
     position:relative;
     font-size:14px;
     display: -webkit-flex;
     display:flex;
     /*line-height: 24px;*/
     box-sizing: border-box;
     border-top:1PX SOLID #393838;
     flex-direction: row-reverse;
     color: #afaeae;
}
 .t_r_i_n_a_d_c_a_t a{
     padding:0px 10px;
     color: #fef;
}
 .t_r_i a{
     margin:0px 10px;
}
 footer a:hover{
     color:#ff513a!important;
     text-decoration:underline;
}
 footer .o_d_i_n {
     display: -webkit-flex;
     display: flex;
     flex-direction: row!important;
     justify-content: left;
     color: #fef;
     align-items: center;
     font-size: 16px;
     flex-wrap: wrap;
}
 .o_d_i_n{
    width:1300px;
     margin:0 auto;
}
 header .o_d_i_n {
     display: -webkit-flex;
     ALIGN-ITEMs: baseline;
     display: flex;
     flex-direction: row;
     align-items: normal;
     height: 100%;
     justify-content: flex-end;
}
 header .o_d_i_n div{
     margin-left: auto;
     display: -webkit-flex;
     ALIGN-ITEMs:center;
     display:flex;
     position: relative;
     margin-right: auto;
}
 .o_d_i_n_n_a_d_c_a_t {
     width: 100%;
     text-align: left;
     box-sizing: border-box;
}
 .p_y_a_t,.s_e_m{
     width: 151px;
     height: 56px;
     border-radius: 30px;
     background: #ff513a;
     transition:.3s;
     color:#fef;
     justify-content: center;
}

.p_y_a_t{
    background:transparent;
    border:1px solid #ff513a;
}

 .p_y_a_t{
     margin-right: 15px!important;
}
 .p_y_a_t:hover,.s_e_m:hover{
     background:#fef;
     color:#ff513a;
}
 .t_r_i div{
     color: #fef;
     font-size: 18px;
     padding: 0px 5px;
}
 .v_o_s_e_m .o_d_i_n{
     display: -webkit-flex;
     display:flex;
     flex-direction: column;
}
 ul {
     padding-left: 30px;
     display: flex;
     list-style: square outside;
     flex-direction: column;
     margin-bottom: 0px;
}
 li{
     font-size: 16px;
     ALIGN-ITEMs: center;
     display: list-item;

     position:relative;
     display: list-item;
}
 .d_e_s_y_a_t img{
     /*width: 100%;*/
     //height: 100%;
   border-radius: 5px;
}
 .d_e_s_y_a_t {
     width: 100%;
     color: #fbfbe5;
     text-align: left;
     line-height: 1.4;
     display: flex;
     flex-direction: row;
}
 .mob_mm{
     display:none!important;
}
 @media screen and (max-width: 1400px) and (min-width: 1000px) {
     .t_r_i div {
         font-size: 18px;
         padding: 0px 5px;
    }
     .d_e_s_y_a_t{
         width: 100%;
    }
     header .o_d_i_n div{
         white-space:nowrap;
    }
     .d_e_s_y_a_t img {
         width: 97%;
    }
     .p_y_a_t {
         padding:0px 10px;
    }
     .s_e_m {
         padding:0px 10px;
    }
     .p_y_a_t {
         width: 104px;
    }
     .o_d_i_n {
        width: 1050px;
    }
}
 @media screen and (max-width: 1000px) {
     .mob_mm{
         display:block!important;
    }
     .d_v_a img {
         width: 100%;
         z-index: 3;
         margin-bottom: 0px;
    }
     .o_d_i_n{
         width:100%;
         padding:0px 5vw;
    }
     footer .o_d_i_n {
         display: -webkit-flex;
         display: flex;
         flex-direction: column;
    }
     .v_o_s_e_m .o_d_i_n {
         padding: 0px 5vw;
         display: -webkit-flex;
         box-sizing: border-box;
         display: flex;
         flex-direction: column;
         /*align-items: baseline;*/
    }
     img{
        /*max-width:90vw;*/
    }
     header{
         display:none;
    }
     .d_e_v_y_a_t {
         margin-bottom: 0px;
         border-radius: 30px;
         margin-top: 10px;
    }
     .mob_mm{
         display:block;
    }
     header {
         height: auto;
         z-index: 100000;
         left: 0px;
         right: 0px;
         height: auto;
    }
     header .o_d_i_n {
         flex-direction: row;
         padding: 0px 5vw;
         flex-wrap: wrap;
         box-sizing: border-box;
         padding-top:10px;
    }
     header{
         margin:0px;
    }
     .c_h_e_t_i_r_n_a_d_c_a_t {
         width: 100%;
         display: flex;
         flex-direction: column;
          align-items: baseline;
         justify-content: end;
         padding-left: 0px;
         box-sizing: border-box;
         margin-top: 10px;
         margin-bottom:20px;
    }
     .t_r_i_n_a_d_c_a_t a {
         padding: 0px 4vw;
         margin-bottom: 10px;
         padding-left: 0px;
    }
     .t_r_i_n_a_d_c_a_t {
         display: flex;
         width: 100%;
         flex-wrap: wrap;
         justify-content: left;
    }
     footer {
         height: auto;
         margin-top: 0px;
    }
     footer .o_d_i_n {
         flex-direction: row-reverse!important;
    }
     footer .o_d_i_n {
         justify-content: center;
         flex-wrap: wrap;
    }
     img {
         /*max-width: 100vw;*/
    }
     .d_v_a {
         margin-left: 0px!important;
         width: 30%;
    }
     .s_h_e_s_t {
         display: flex;
         /*width: 60%;*/
         flex-direction: row;
         justify-content: space-between;
    }
     .s_e_m {
         margin-right: 3vw!important;
         height: 40px!important;
         width: 40vw;
         margin-right:0px!important;
    }
     header .o_d_i_n {
         height: auto;
    }
     h1:before, strong:before{
        display:none;
    }
     strong, h1 {
         padding-left: 0px;
    }
     .t_r_i div {
         color: #fef;
         font-size: 16px;
         padding: 0px 0px;
    }
     .p_y_a_t {
         padding: 0px 1vw;
         font-size: 14px;
         width: 20vw;
         height: 40px!important;
    }
     .s_e_m {
         padding: 0px 1vw;
         font-size: 14px;
         /*width: 29vw;*/
         height: 40px!important;
    }
     .d_v_a img {
         width: 100%;
         z-index: 3;
         margin-top: 0px;
         display: block;
    }
     header .t_r_i div:hover:before {
         top:12px;
         display:none;
    }
     p strong{
         margin-top:0px;
    }
}
 @media screen and (max-width: 1000px) {

     p strong{
         padding-left:0px;
     }
     .t_r_i a {
         margin: 0px 10px;
         white-space: nowrap;
         padding: 25px 0px;
    }
     .t_r_i{
         overflow: scroll;
         -webkit-overflow-scrolling:touch;
    }
     h1:before, p strong:before{
        display:none;
    }
     p, li, h1{
        padding-left:0px;
    }
     .o_d_i_n_n_a_d_c_a_t{
         width:100%;
         padding-right: 0px;
    }
     .d_e_s_y_a_t{
         width:100%;
    }
     p, li {
         line-height: 1.6;
         font-size: 14px;
    }
     header .t_r_i div:hover:after {
         display:none;
    }
     p strong{
         font-size:18px;
    }
}
.goldenreels {
  cursor: pointer;
  //width: 100%;
  }

A {
    /*font-size: 14px;*/
    color: #ff513a;
}

a:hover {
  color: #6c737C;
}

 .d_v_e_n_a_d_c_a_t{
     background: #ff513a;
     border-radius: 15px;

     padding: 15px;
     border:1px solid #1b1919;
     font-size: 16px;
 }

 .d_v_e_n_a_d_c_a_t{
     /*margin-right: 15px!important;*/
 }
 .d_v_e_n_a_d_c_a_t:hover,.s_e_m:hover{
     /*background:#fef;*/
     color:#feFFF1;
 }

// pulse button


.blob.green {
  //background: rgba(63, 81, 181, 1);
  box-shadow: 0 0 0 0 rgba(63, 81, 181, 1);
  animation: pulse-green 2s infinite;
  border-radius: 5px;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(63, 81, 181, 0.7);
  }

  70% {
    transform: scale(1.00);
    box-shadow: 0 0 0 10px rgba(63, 81, 181, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(63, 81, 181, 0);
  }
}